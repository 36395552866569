import React, { useState, useEffect } from "react";
import "./App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  const [randomString, setRandomString] = useState("");
  const [fade, setFade] = useState(false);

  const generateRandomString = () => {
    let resultString;
    let hasNumber = false;

    do {
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789";
      let result = ["N"]; // Initialize result with 'N'
      characters += "0123456789"; // Ensure there's a higher chance to include a number
      const charactersLength = characters.length;

      for (let i = 1; i < 6; i++) {
        // Start from 1 since 'N' is already added
        const char = characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        if (char >= "0" && char <= "9") {
          hasNumber = true;
        }
        result.push(char);
      }
      resultString = result.join("");
    } while (!hasNumber); // Ensure there's at least one number

    // Convert string to array of JSX elements for rendering
    return resultString.split("").map((char, index) => {
      if (char === "0") {
        return (
          <span className="zero-style" key={index}>
            0
          </span>
        );
      } else {
        return <span key={index}>{char}</span>;
      }
    });
  };

  const changeString = () => {
    setFade(true);
    setTimeout(() => {
      setRandomString(generateRandomString());
      setFade(false);
    }, 500); // the timeout duration should match your CSS animation duration
  };

  useEffect(() => {
    setRandomString(generateRandomString());
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      changeString();
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <div className="App" onClick={changeString}>
      <header className="App-header">
        <div className="App-logo">
          <i className="fas fa-plane"></i>
          <span className="logo-text">Tail Wing Generator</span>
        </div>
      </header>
      <div className={`Random-string ${fade ? "fade" : ""}`}>
        {randomString}
      </div>
    </div>
  );
}

export default App;
